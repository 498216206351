import * as React from 'react';
import { WsTradeConnector, ApiGatewayLoginWsConnector, WsConnector } from '../ws-connector';
import { WS_GATEWAY_URL, WS_GATEWAY_LOGIN_URL, WS_MARKET_URL } from 'config';

export const wsConnectorTradeGateway = new ApiGatewayLoginWsConnector(WS_GATEWAY_LOGIN_URL);
export const wsTradeConnectors = {};

export const wsConnectorMarket = new WsConnector('');
export const wsConnectorResearch = new WsConnector('');
export const wsConnectorDataprocessor = new WsConnector('');
export const wsConnectorDistGateway = new WsConnector('');

export function WebSockerWrapper({ children }) {
  const childrenWithProps = React.Children.map(children, child => {
    if (React.isValidElement(child)) {
      return React.cloneElement(child, {  });
    }
    return child;
  });
  return <>{childrenWithProps}</>
}
