import ReconnectingWebSocket from 'reconnecting-websocket';
import { extraLogging } from '../config';
import { $$ } from '../utils/utils';
import { getCachedValue, refreshCachedValue, setCachedValue } from '../utils/local_storage';

export class WsConnector {
    url: string;
    connect_message: any | null = null;
    ws: ReconnectingWebSocket | null = null;
    on_message_handler: any | null = null;
    on_connect_handler: any | null = null;
    on_error_handler:   any | null = null;
    on_close_handler:   any | null = null;
    ready = false;
    halted = false;
    params: {} = {};
    postponed_message: any | null = null;

    constructor(url: string, params: any | null = null) {
        this.url = url;
        if (params) {
            this.params = params;
        }
    }

    halt() {
        this.halted = true;
    }

    on_close(e) {
        // console.log('++++++++++++++++++++++ CLOSED ++++++++++++++++++++++')
        if (this.on_close_handler) {
            this.on_close_handler(this);
        }
        extraLogging && console.log('WS Closed')
    }

    on_error(e) {
        console.log('++++++++++++++++++++++ ERROR ++++++++++++++++++++++')
        if (this.on_error_handler) {
            this.on_error_handler(e);
        }
        extraLogging && console.warn('WS Error', e);
    }

    on_open() {
        extraLogging && console.log('WS Open')
    }

    on_message(e) {
        if (this.on_message_handler) {
            this.on_message_handler(e);
        }
    }

    set_on_message(handler) {
        this.on_message_handler = handler;
        if (this.ready && this.ws) {
            this.ws.onmessage = handler;
        }
    }

    init_receiver(handler) {
        this.on_message_handler = handler;
        if (!this.ready || !this.ws) {
            this.ws = this.connect()
        }
    }

    connect() {

        const self = this;
        this.ws = new ReconnectingWebSocket(self.url, undefined, this.params);
        extraLogging && console.log('================= WS created: [' + self.url + '] ================');
        this.ws.onopen = () => {
            self.ready = true;
            self.on_open();
            self.on_connect();
            extraLogging && console.warn('================= WS opened: [' + self.url + '] ================');
        }
        this.ws.onclose = (e) => {
            self.ready = false;
            self.on_close(e);
            extraLogging && console.log('WS closed: [' + self.url + ']');
        }
        this.ws.onerror = (e) => {
            self.ready = false;
            self.on_error(e);
            extraLogging && console.log('WS error: [' + self.url + ']');
        }
        this.ws.onmessage = (e) => {
            self.on_message(e);
            // extraLogging && console.log('ON MESSAGE: [' + self.url + ']');
        }
        return this.ws;
    }

    disconnect() {
        if (this.ws) {
            this.ws.close();
            this.ws = null;
            this.ready = false
        }
        extraLogging && console.log("WS Disconnected: [" + this.url + "]")
    }

    on_connect() {
        // console.log('++++++++++++++++++++++ CONNECTED ++++++++++++++++++++++')
        if (this.connect_message) {
            this.send(this.connect_message);
            extraLogging && console.log("##### connect message sent: ", this.connect_message);
        }
        if (this.on_connect_handler) {
            this.on_connect_handler(this);
        }

        if (!!this.postponed_message) {
            extraLogging && console.log('WS postponed_message - RESEND');
            this.send_event(this.postponed_message['data'], this.postponed_message['callback']);
            this.postponed_message = null;
        }
    }

    send_event(data, callback: any = null) {
        if (!this.halted) {
            if (data) {
                if (!this.ws) {
                    this.postponed_message = {data, callback};
                    this.ws = this.connect();
                    return "POSTPONED"
                }
                if (callback) {
                    this.ws!.onmessage = callback;
                }
                this.ws.send(JSON.stringify(data));
                extraLogging && console.log("##### message sent: ", data);
                return "SENT"
            }
        }
        return "SKIPPED";
    }

    send(data, callback: any = null) {
        this.send_event(data, callback);
    }
}

export class WsTradeConnector extends  WsConnector {
    seqn: number = 0;
    account_name: string;

    constructor(url: string,
                account_name,
                on_connect_callback: (ws: WsTradeConnector) => void,
                on_message_callback: (ws: WsTradeConnector) => void,
                on_error_callback: (ws: WsTradeConnector) => void,
                params = null) {
        const ws_options = {
            maxReconnectionDelay: 2000,
            reconnectionDelayGrowFactor: 1.5,
            connectionTimeout: 5000,
        }
        super(url, ws_options);
        this.account_name = account_name;
        this.on_message_handler = on_message_callback;
        this.on_connect_handler = on_connect_callback;
        this.on_error_handler   = on_error_callback;
    }

    send(data, callback: any = null) {
        this.seqn += 1;
        const real_mess = {...data, 'seqn': this.seqn, "ts": 1000 * $$.getDateTimestampMs()}
        console.log('************************************', real_mess);
        super.send_event({...data, 'seqn': this.seqn, "ts": 1000 * $$.getDateTimestampMs()}, callback);
        refreshCachedValue('_user');
    }

    on_close(e) {
        super.on_close(e);
    }
}

export class ApiGatewayLoginWsConnector extends WsConnector {
    constructor(url: string, params = null) {
        super(url, params);

        const user = getCachedValue('_user');
        if (user) {
            setTimeout(() => {
                try {
                    this.send({
                        "msg": "LogonRequest",
                        "token": user.password,
                        "traderId": parseInt(user.name)});
                } catch (err: any) {
                  console.warn("Reconnect websocket ERROR", err);
                }
            }, 300)
        }
    }
}
