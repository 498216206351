import { wsTradeConnectors } from '../../components/system/websocket-component';
import { AccountInfo } from './trade-types';
import { WsTradeConnector } from '../../components/ws-connector';
import { UserProfile } from '../../types/auth';
import { initAccountSubscription } from './trade-lab-actions';
import { stopIntervals } from './trade-lab-common';

const exchangesTradeWss = {
  // 'BNF': 'ws://3.78.53.123:8099',
  'BNF': 'ws://3.78.53.123:8099',
  // 'BNS': 'ws://3.78.53.123:8088',
}

export const halt_connection = (account_name: string)=> {
  const WS = wsTradeConnectors[account_name];
  if(!!WS) {
      WS.disconnect();
      WS.halt();
      delete wsTradeConnectors[account_name];
  }
}

// const data_for_reconnect = {}
//
// export const _reconnect = (account_name: string)=> {
//   console.warn(' $$$$$$$$$$$$$$$$$$$$$$$$$$$ RECONNECT ******************')
//   halt_connection(account_name);
//   const connect = data_for_reconnect[account_name];
//   initTradeWs('logIn', connect['user'], connect['account'], connect['performer']);
// }
//
// export const reconnect = (account_name: string)=>
//   setTimeout(() => _reconnect(account_name), 5000);

export const initTradeWs = (
  mode: 'logIn' | 'logOut',
  user: UserProfile,
  account: AccountInfo,
  performer: (data: Object) => void)=>
{
    // console.log("============ ACCOUNT", account);

    const account_name  = account.account_name;
    const exchange_name = account.exchange_name;

    let WS = wsTradeConnectors[account_name];

    if (! WS) {
      if (mode == 'logOut') {
        console.warn('############### ATTENTION. Websocket not initialised.', account_name);
        return null;
      }

      const url = exchangesTradeWss[exchange_name];
      if (!url) {
        console.warn("############### ATTENTION. Please define URL for '" + exchange_name + "'");
        return "not configured"
      }

      const on_message =
        payload => performer({ ...JSON.parse(payload.data || '{}'), account_name: account_name });

      const on_error = (ws) => {
        performer({msg: '_ConnectionError_', errMessage: 'Disconnected. Please wait. Should be restored automatically', account_name });
      }

      const on_connect = (ws) => {
        // console.log("######################### on_connect - LOG IN ############################");
        ws.send({
          "msg": "LogonRequest",
          "token": user.password,
          "account": account_name,
          "traderId": user.name,
        });
        initAccountSubscription(account_name);
      }

      WS = new WsTradeConnector(url, account_name, on_connect, on_message, on_error);
      WS.connect();

      wsTradeConnectors[account_name] = WS;
      // console.log("************************** CONNECTED [" + account_name + "] **************************")
    }

    if (mode == 'logIn') {
      /* do nothing here - AUTO LOGIN will be applied */
      // initAccountSubscription(account_name);
    }
    if (mode == 'logOut') {
        // console.log("************************** LOG OUT **************************")
        /* It is not required logoff for this kind of WS - just need disconnect */
        halt_connection(account_name)
        stopIntervals(account.account_name);
        performer({'msg': 'LogoffReply', account_name});
    }
    return null;
}
