import { User } from '../models/user';

export const getCachedValue = (name: string) => {
 const content = window.localStorage.getItem(name);
 const now = Date.now().valueOf();
 if (content) {
  const value = JSON.parse(content);
  if (! value.expiration || (value.expiration > 0 && value.expiration < now)) {
   window.localStorage.removeItem(name);
   return null;
  }
  if (value.data) {
   return value.data;
  }
  return null;
 }
}

export const refreshCachedValue = (name: string, expiration_sec: number = 60 * 10) => {
 const data = getCachedValue(name);
 if (data) {
   setCachedValue(name, data, expiration_sec);
   return data;
 }
 return null;
}

export const removeCachedValue = (name: string) => {
 const content = window.localStorage.getItem(name);
 if (content) {
   window.localStorage.removeItem(name);
 }
}

export const setCachedValue = (name: string, data: any, expiration_sec: number = 60 * 10) => {
 const expiration = expiration_sec  > 0 ? Date.now().valueOf() + expiration_sec * 1000 : -1;
 return  window.localStorage.setItem(name,  JSON.stringify({ data, expiration }));
}


export const setLocalStorageValue = (name: string, data: any) => {
  window.localStorage.setItem(name, JSON.stringify(data));
}

export const removeLocalStorageValue = (name: string) => {
  window.localStorage.removeItem(name);
}

export const getLocalStorageValue = (name: string) => {
 const content = window.localStorage.getItem(name);
 return content ? JSON.parse(content) : undefined;
}
