import { createSlice } from '@reduxjs/toolkit'

import { balanceKey, COMPONENT_KEY_NAME, positionKey, startCheckInterval, stopIntervals } from './trade-lab-common';
import { AsyncApiStatus } from 'models/nb-models';
import { AccountInfo, AssetBalance, initialTradesTracker, Position, Trade } from './trade-types';
import { getLocalStorageValue, setCachedValue, setLocalStorageValue } from '../../utils/local_storage';
import { traderPositionTtlSeconds } from '../../config';
import { $$ } from '../../utils/utils';


interface InitialState {
  loading: AsyncApiStatus;
  sincTime: number;
  responseCounter: number;
  responseCounterTrades: number;

  accounts: AccountInfo[];
  accountsShowList: boolean;

  balances: Record<string, AssetBalance>;
  balanceList: AssetBalance[];
  balanceMode: {};
  balanceExpanded: number;
  balanceHistory: {},
  balanceHistoryValues: {},

  positions: Record<string, Position>;
  positionList: Position[];
  positionMode: {};
  positionExpanded: number;
  positionHistory: {};
  positionHistoryValues: {};

  trades: Record<number, Trade>;
  tradeList: Trade[];
  tradeMode: {};
}

const initialState: InitialState = {
  loading: 'idle',
  sincTime: 0,
  responseCounter: 0,
  responseCounterTrades: 0,

  accounts: [],
  accountsShowList: true,

  balances: {},
  balanceList: [],
  balanceMode: {},
  balanceExpanded: -1,
  balanceHistory: {},
  balanceHistoryValues: {}, // not used yet

  positions: {},
  positionList: [],
  positionMode: {},
  positionExpanded: -1,
  positionHistory: {},
  positionHistoryValues: {}, // not used yet

  trades: {},
  tradeList: [],
  tradeMode: {},
};

const INITIAL_ACCOUNT_VIEW_MODE = 'not_applied';

const getSorter = (textFields: string[], name: string, reversed: boolean) => {
  let normalizer = a => a;
  if (!textFields.includes(name))     normalizer = a => parseFloat(a);
  if ([ 'ts', 'clientOrderId' ].includes(name)) normalizer = a => parseInt(a) / 1000;
  const rever = reversed ? 1 : -1;
  if (textFields.includes(name)) {
    console.log("NAME: " + name + ']');
      return (a: any, b: any) => rever * a[name].localeCompare(b[name]);
  }

  else {
    return (a: any, b: any) => rever * normalizer(a[name]) > normalizer(b[name]) ? 1 : -1;
  }
};


const getAccountByName = (state, account_name: string) => {
    for (const item of state.accounts) {
     if (item.account_name === account_name) {
          return item;
        }
    }
    return undefined;
  }

export const tradeLabSlice = createSlice({
  name: COMPONENT_KEY_NAME,
  initialState,
  reducers: {
    applyAction(state, action) {
      const { mode, name, value } = action.payload;
      console.log('############################ applyAction. payload::',  action.payload)

      if (mode === 'trades') {
        const textFields = [ 'symbol', 'status', 'side', 'account', 'commissionAsset' ];
        state.tradeList.sort(getSorter(textFields, name, value));
        state.tradeMode = { ...action.payload };
      }

      if (mode === 'balances') {
        if (name === '_expand_') {
          state.balanceExpanded = value === state.balanceExpanded ? -1 : value;
        } else {
          const textFields = [ 'asset', 'account' ];
          state.balanceList.sort(getSorter(textFields, name, value));
          state.balanceMode = { ...action.payload };
        }
      }

      if (mode === 'positions') {
        if (name === '_expand_') {
          state.positionExpanded = value === state.positionExpanded ? -1 : value;
        } else {
          // name = '0', '5'
          const textFields = [ 'instrumentCode', 'account', 'symbol' ];
          state.positionList.sort(getSorter(textFields, name, value));
          state.positionMode = { ...action.payload };
        }
      }

      return state;
    },

    updateInstantValues(state, action) {
      const data = action.payload;
      const account_name = action.payload.account_name;
      const msg = data.msg;
      delete data['msg'];
      const errCode = data['errCode'];
      const errMessage = data['errMessage'];
      const wrkMsgPrefix = '_';

      console.log('*********** ############# ACTION.PAYLOAD msg::[' + msg + ']', action.payload);

      if (errCode === 74) {
        //{"msg":"PositionQueryReply","ts":1739300357301000,"seqn":2,"positions":[],"errCode":74,"errMessage":"User not logged on"}
        const account = getAccountByName(state, account_name);
        return state;
      }

      const account = getAccountByName(state, account_name);

      if (!msg.startsWith(wrkMsgPrefix) && !!account) {
        account._lastTick = $$.getDateTimestampMs();
        state.responseCounter += 1;
      }

      if (['LogonReply', 'LogoffReply'].includes(msg)) {
        if (msg === 'LogonReply') {
          console.warn("@@@@@@@@@@@@@@@@@@@@@@@@@@@ LOGIN @@@@@@@@@@@@@@@@@@@@@@@@@")
        }

        state.accounts.forEach(item => {
          if (item.account_name === account_name) {
              item._loading = false;
              console.log("########################### account._loading::", item._loading)
              if (data['result'] === 'success') {
                item._error = '';
              }
              else {
                item._error = data['errMessage'] || 'ERROR';
              }
          }
        });
      }
      if (msg === 'AssetBalance') {
        const balance = data as AssetBalance;
        state.balances[balanceKey(balance)] = {...balance, account: account_name};
      }
      if (msg === 'AssetBalanceQueryReply') {
        action.payload.balances.forEach(balance => state.balances[balanceKey(balance)] = {...balance, account: account_name});
      }
      if (['AssetBalance', 'AssetBalanceQueryReply'].includes(msg)) {
        state.balanceList = Object.values(state.balances);

        state.balanceList.forEach((balance: AssetBalance) => {
            const key = balanceKey(balance);
            const currentBalance = state.balances[key];
            if (currentBalance) {
              const balanceHistory = state.positionHistory[key] || {};
              balanceHistory['0'] = parseFloat(balance.available) - parseFloat(currentBalance.available);
              state.balanceHistory[key] = balanceHistory;
            }
          })
      }
      if (msg === 'PositionQueryReply') {
          const positions = data['positions'] as Position[];

          positions.forEach((position: Position) => {
            const key = positionKey(position);
            const currentPosition = state.positions[key];
            if (currentPosition) {
              const positionHistory = state.positionHistory[key] || {};
              positionHistory['0'] = parseFloat(position.position) - parseFloat(currentPosition.position);
              state.positionHistory[key] = positionHistory;
            }
            state.positions[key] = position;
          })
          state.positionList = Object.values(state.positions);
          setCachedValue('_positions', state.positionList, traderPositionTtlSeconds);
      }
      if (msg === 'TradeQueryReply') {
          const symbol = data['symbol'];
          const trades = data['trades'] || [];
          trades.forEach(trade => state.trades[trade.tradeId] = {...trade, account_name});
          state.tradeList = Object.values(state.trades);
          state.responseCounterTrades += 1;
          account._tradesTracker = account._tradesTracker || initialTradesTracker();

          account._tradesTracker!.responseCounter += 1;
          account._tradesTracker!.lastPackSize = trades.length;
      }
      if (msg === 'OrderQueryReply') {
        console.log('OrderQueryReply', msg);
        return state;
      }
      if (msg === '_PositionRestoreAfterRefresh_') {
          state.positionList = Object.values(data);
          state.positionList.forEach(position => {state.positions[positionKey(position)] = position});
      }
      if (msg === '_ConnectionError_')  {
          const error = data['errMessage'] || 'ERROR';
          state.accounts.forEach(account => { if (account.account_name === account_name) {
              account._error = error;
              stopIntervals(account_name);
          }});
        return state;
      }
      return state;
    },
    diffCalculate(state, action) {
      const {target, interval, account_name} = action.payload;
      // console.log("################ diffCalculate::[" + account_name + "]", action.payload);
      return state;
    },
    setAccounts(state, action) {
      if (state.accounts.length === 0) {
          state.accounts = [...action.payload];
          state.accounts.forEach(account => account._viewMode = INITIAL_ACCOUNT_VIEW_MODE);
      }
      return state;
    },

    toggleAccountsShowList (state, action) {
        state.accountsShowList = !state.accountsShowList;
        return state;
    },

    sincTimeUpdate (state, action) {
        state.sincTime = $$.getDateTimestampMs();
        return state;
    },

    toggleAccount(state, action) {
      const account = action.payload;
      const account_name = account.account_name;
      state.accounts.forEach(item => {
          if (item.account_name === account_name) {
            item._viewMode = item._viewMode === 'applied' ? 'not_applied': 'applied';
            let appliedAccounts = getLocalStorageValue('_appliedAccounts') || [];
            if (item._viewMode === 'applied') {
              appliedAccounts.push(account_name);
            }
            else {
              appliedAccounts = appliedAccounts.filter(a => a !== account_name);
            }
            setLocalStorageValue('_appliedAccounts', appliedAccounts);
          }
      })
      return state;
    },

    updateAccountStatus(state, action) {
      const {account, mode, error, loading} = action.payload;
       state.accounts.forEach(item => {
          if (item.account_name === account.account_name) {
            item._loading = loading;
            item._error = error;
          }
       });
      return state;
    },

    loadAccountApplying(state, action) {
      const appliedAccounts = action.payload;
      appliedAccounts.forEach(account_name => {
          state.accounts.forEach(account => {
            if (account.account_name === account_name) {
              account._viewMode = 'applied';
              if (!account._tradesTracker) {
                account._tradesTracker = initialTradesTracker();
              }
          }})
      })
      return state;
    }
  }
});



export const tradeLabSliceActions = tradeLabSlice.actions;
export default tradeLabSlice.reducer;
