import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import useAuth from 'hooks/useAuth';

// types
import { GuardProps } from 'types/auth';
import {restoreUser} from "../../store/reducers/auth";
import { isTraderApp } from '../../config';

// ==============================|| AUTH GUARD ||============================== //

const AuthGuard = ({ children }: GuardProps) => {
  const { isLoggedIn, user } = useAuth();
  const navigate = useNavigate();
  // alert("login!")
  // console.log(useAuth())
  // const user = restoreUser();
  // console.log(!!user)

  useEffect(() => {
    if (!isLoggedIn ) {
      navigate('login', { replace: true });
    }
  }, [isLoggedIn, navigate]);

  return children;
};

export default AuthGuard;
