import React, { useMemo } from 'react';
import { useNavigate  } from 'react-router-dom';
import { Theme, useTheme } from '@mui/material/styles';
import { Box, FormControl, InputAdornment, OutlinedInput, Stack, Typography, useMediaQuery,Grid,IconButton,Menu,MenuItem } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import {
  buttonBackgroundColor,
  headerBackgroundColor, isAdminApp,
  isPlatformApp, isTraderApp,
  mainTextColor,
  primaryTextColor,
  RESOLUTION_SCREEN
} from 'config';
import AppRoutes from 'routes/AppRoutes';
import { goTo } from 'utils/react-utils';
import { TabItem, TabsComponent } from 'components/tab-component';
import Profile from './Profile';
import useAuth from 'hooks/useAuth';
import { isSuperuser, UserProfile } from 'types/auth';
import StrategyDemoHeader from "./strategy-demo-header";


const TabItems = [
    { title: 'Markets',    id: AppRoutes.NB_HOME},
    { title: 'Wealth Lab', id: AppRoutes.WEALTH_LAB},
    { title: 'Trade Lab',  id: AppRoutes.TRADE_LAB}

] as TabItem[];

const goToPage = item => goTo(item.id)();
const gogo = (item) => {
    useNavigate()(item.id);
}

const resolutionScreen = window.screen.width > window.screen.height ? 'horizontal' : 'vertical';
const fontResolution = RESOLUTION_SCREEN === 'horizontal' ? '12px' : '8px';
const widthBoxResolutionW = RESOLUTION_SCREEN === 'horizontal' ? '12.5%' : '15%';
const widthBoxResolutionT = RESOLUTION_SCREEN === 'horizontal' ? '11%' : '14%';

const HeadMenu = () => {
const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
const open = Boolean(anchorEl);

const openMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
};
const closeMenu = () => {
    setAnchorEl(null);
};

const goToAndClose = (nav) => {
    goTo(nav);
    // closeMenu();
};

   return ( <>
        {resolutionScreen === 'horizontal' ? <>
        <Box sx={{ width: '40%',paddingLeft: '28px' }}>
            <Stack direction={'row'}  alignItems={'center'} justifyContent={'flex-start'} spacing={3} >
              {isPlatformApp() && <>
                <Typography onClick={goTo(AppRoutes.DASHBOARD_MARKET_SUMMARY)} variant={'inherit'} color={primaryTextColor}
                sx={{":hover":{
                        color: buttonBackgroundColor},
                    cursor:'pointer'
                }}>
                    Markets
                </Typography>
                <Stack direction={'row'} spacing={0.5} position={'relative'} width={widthBoxResolutionW} height={'26px'} alignItems={'center'}
                    onClick={goTo(AppRoutes.WEALTH_LAB)}
                    sx={{cursor:'pointer'}}
                >
                    <Box sx={{textAlign:'center',color:primaryTextColor,":hover":{color: buttonBackgroundColor},}}>Wealth</Box>
                    <Box sx={{fontSize:fontResolution, position:'absolute', color:buttonBackgroundColor,top:'0px', right:'0px'}}>Lab</Box>
                </Stack>
              </>}
              {isTraderApp() &&
                <Stack direction={'row'} spacing={0.5} position={'relative'} width={widthBoxResolutionT} height={'26px'} alignItems={'center'}
                     onClick={goTo(AppRoutes.TRADE_LAB)}
                    sx={{cursor:'pointer'}}
                >
                    <Box sx={{textAlign:'center',color:primaryTextColor}}>Trade</Box>
                    <Box sx={{fontSize:fontResolution, position:'absolute', color:buttonBackgroundColor,top:'0px', right:'0px'}}>Lab</Box>
                </Stack>
              }
            </Stack>
        </Box>
            </>
            : <><Box sx={{ width: '15%'}}>
                <IconButton
                    id={'basic-menu'}
                    className={'menuButton'}
                    size={'large'}
                    edge={'start'}
                    aria-label={"menu"}
                    onClick={openMenu}
                >
                   <MenuIcon/>
                </IconButton>
                <Menu
                    id="basic-menu"
                    anchorEl={anchorEl}
                    open={open}
                    onClose={closeMenu}
                    MenuListProps={{
                        'aria-labelledby': 'basic-button',
                    }}
                >
                  {isPlatformApp() && <>
                    <MenuItem onClick={closeMenu}>
                        <Typography onClick={goTo(AppRoutes.DASHBOARD_MARKET_SUMMARY)} variant={'inherit'} color={primaryTextColor}
                                    sx={{":hover":{
                                            color: buttonBackgroundColor},
                                        cursor:'pointer'
                                    }}>
                            Markets
                        </Typography>
                    </MenuItem>
                    <MenuItem onClick={closeMenu}>
                        <Typography onClick={goTo(AppRoutes.WEALTH_LAB)} variant={'inherit'} color={primaryTextColor}
                                    sx={{":hover":{
                                            color: buttonBackgroundColor},
                                        cursor:'pointer'
                                    }}>
                            Wealth Lab
                        </Typography>
                    </MenuItem>
                    </>}
                   {isTraderApp() &&
                    <MenuItem onClick={closeMenu}>
                        Trade Lab
                    </MenuItem>
                   }
                </Menu>
            </Box>
            </>
        }

    </>
   )
};
const StrategiesHeadMenu = () => {
    return(<>
        <Box sx={{ width: '40%',paddingLeft: '28px' }}>
             <StrategyDemoHeader />
        </Box>
    </>)
}


const HeaderTitle = () => <Box sx={{ width:{sm:'31%',md:'32%',lg:'36%'}, marginTop: '8px' }}>
    {resolutionScreen === 'horizontal' ?
    <img src={'/NB-Logo.png'} alt={'logo'} style={{width:'150px'}}/> : <img src={'/NB-Logo.png'} alt={'logo'} style={{width:'150px'}}/>}
</Box>;


const HeaderContent = () => {
  // const matchesXs = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));
  const matchesXs = useMediaQuery((theme: Theme) => theme.breakpoints.down('xs'));
  // const matchesXs = useMediaQuery('(min-width:820px)');

  const { user } = useAuth();
  const isAdmin = user ? isSuperuser(user as UserProfile) : false;
  const isDemoUser = user && user.email?.includes('demo-user@newbridge.ai');

  return (
    <>
        <Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'}  sx={{width:'100%'}}>
            {!matchesXs && !isDemoUser ?  <HeadMenu /> : <StrategiesHeadMenu/>}
            {/*{!matchesXs && <HeadMenu />}*/}
            {!matchesXs && <HeaderTitle />}
            {matchesXs && <Box sx={{ width: '100%', ml: 1 }} />}
            {isAdminApp() && isAdmin &&
                <Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'} spacing={2}>
                  <Typography onClick={goTo(AppRoutes.LIVE_DATA_PAGE)} variant={'inherit'} color={primaryTextColor}
                     sx={{":hover":{ color: buttonBackgroundColor}, cursor:'pointer' }}>Live Data</Typography>
                  <Typography onClick={goTo(AppRoutes.ADMIN_PAGE)} variant={'inherit'} color={primaryTextColor}
                    sx={{":hover":{ color: buttonBackgroundColor}, cursor:'pointer' }}>Admin</Typography>
                   <Typography onClick={goTo(AppRoutes.DATA_QUALITY_PAGE)} variant={'inherit'} color={primaryTextColor}
                     sx={{":hover":{ color: buttonBackgroundColor}, cursor:'pointer' }}>DQ</Typography>
                </Stack>
            }

            {!matchesXs && <Profile/>}

        </Stack>
    </>
  );
};

export default HeaderContent;
