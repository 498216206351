
import { AssetBalance, Position, Trade } from './trade-types';
import React, { useEffect } from 'react';
import { $$ } from '../../utils/utils';
import { styled } from '@mui/material/styles';
import { CaretDownOutlined, CaretUpOutlined } from '@ant-design/icons';

const Td = styled('td')({
  verticalAlign: 'top',
  paddingLeft: '5px',
  paddingRight: '5px',
});


interface TradeTradesProps {
  trades: Trade[];
  onAction: (a: any) => void;
  sortMode: {};
}

const TradeTradesTable = (props: TradeTradesProps) => {
  const { onAction, trades, sortMode } = props;

  const sortedBy = (field: string) => {
    return field !== sortMode['name']
      ? <CaretUpOutlined style={{color: 'grey'}} className={'tableArrowUp'} />
      : sortMode['value']
          ? <CaretUpOutlined   style={{color: 'red'}} className={'tableArrowUp'} />
          : <CaretDownOutlined style={{color: 'red'}} className={'tableArrowDown'} />};

  const applySortBy = (name: string) => {
    onAction({mode: 'trades', name: name, value: !sortMode['value'] });
  }

  const fields = [
    {name:'ts', title: 'date', render: (trade) => $$.getDateTimeAsStringFromMs(trade.ts / 1000)},
    {name:'symbol'},
    {name:'tradePrice', title: 'price'},
    {name:'account_name', title: 'account'},
    {name:'side'},
    {name:'status'},
    {name:'maker'},
    {name:'execQty'},
    {name:'commission'},
    {name:'commissionAsset'},
    // {name:'clientOrderId'},
    {name:'exchangeOrderId'},
    {name:'tradeId'}];

  const getHeader = (field: any) => {
    const name = field.name;
    const title = field.title || field.name;
    return <Td key={field.name}>{title} <span className={'preventSelect'} onClick={() => applySortBy(name)}>{sortedBy(name)}</span></Td>};

  const getValue = (trade: Trade, field: any) =>
    <Td key={field.name}>{ field.render ? field.render(trade) : trade[field.name]} </Td>;

  return <table style={{ width: '100%' }}>
    <thead>
    <tr key={'orders-Header'} style={{backgroundColor: '#002553'}}><td  colSpan={fields.length}>&nbsp;&nbsp;&nbsp;TRADES</td></tr>
    <tr>
      {fields.map((field: any, index: number) => getHeader(field))}
    </tr>
    </thead><tbody>

  {trades.map((item, index) => {
    const trade = item as Trade;
    return <tr key={trade.tradeId}>
        {fields.map((field: any, index: number) => getValue(trade, field))}
        </tr>})}
    </tbody>
  </table>
}

export default TradeTradesTable;
