import { REQUEST_INTERVAL, subscriptionIntervals, TRADES_HISTORY_DEPT, TRADES_LIMIT } from './trade-lab-common';
import { wsConnectorTradeGateway, wsTradeConnectors } from '../../components/system/websocket-component';
import { $$ } from '../../utils/utils';
import { WsConnector } from '../../components/ws-connector';


export const sendMessage = (ws: WsConnector, msg)=> {
      ws.send(msg)
  }

export const sendTradeRequest = (account_name: string,
                                 startTs: number | null = null,
                                 endTs: number | null = null)=> {
  const ws = getWsByAccountName(account_name);
  const message = {"msg":"TradeQuery",  "limit" : TRADES_LIMIT};

  if (!!startTs) message['endTs'] = startTs;
  if (!!endTs) message['endTs'] = endTs;
  sendMessage(ws, message);
}


export const getWsByAccountName = (account_name: string) =>  wsTradeConnectors[account_name];

export const doAction = (ws: WsConnector, action: string)=>  {
      const startTs = 1000 * $$.getDateTimestampMs($$.getYesterday());

      if (action == "PositionQuery") {
        sendMessage(ws, { "msg": "PositionQuery" });
      }
      if (action == "Symbols") {
        sendMessage(ws, { "msg": "Symbols", "pattern": "*" });
      }
      if (action == "KlineQuery") {
        sendMessage(ws, { "msg": "KlineQuery", "interval": 60, "symbol" : "ETHUSDT", "startTs": startTs });
      }
      if (action == "TradeQuery") {
        sendMessage(ws, {"msg":"TradeQuery",  "limit" : TRADES_LIMIT});
      }
  }


export const initAccountSubscription = (account_name: string) => {
      const checkIntervalName   = `check-${account_name}`;
      const requestIntervalName = `reqst-${account_name}`;

      if(!subscriptionIntervals[requestIntervalName]) {
        const ws = getWsByAccountName(account_name);
        if (ws) {
          doAction(ws, 'PositionQuery');
          sendTradeRequest(account_name);
        }
        subscriptionIntervals[requestIntervalName] = setInterval(() => {
          console.log("+++++++++++++++++++++++++++++++ INTERVAL [" + account_name + ']')
          doAction(ws, 'TradeQuery');
          sendTradeRequest(account_name);
        }, 1000 * REQUEST_INTERVAL);
      }
  }

// export const xxx = () => {
//     if(!subscriptionIntervals[checkIntervalName]) {
//         // subscriptionIntervals[checkIntervalName] =
//         const intervalTimeout = setInterval(() => {
//             // console.log("+++++++++++++++++++++++++", new Date());
//             this.props.diffCalculate({target: 'account', interval: '5', account_name});
//             this.props.diffCalculate({target: 'balance', interval: '5', account_name});
//           }, 20 * 1000);
//         subscriptionIntervals[checkIntervalName] = intervalTimeout;
//         // subscriptionTimeouts[checkIntervalName] = timeout;
//     }
// }
