import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import config from 'config';
import useAuth from 'hooks/useAuth';

import { GuardProps } from 'types/auth';
import AppRoutes from '../../routes/AppRoutes';

// ==============================|| GUEST GUARD ||============================== //

const GuestGuard = ({ children }: GuardProps) => {
  const { isLoggedIn, user, needLoggedAsTrader, isLoggedInAsTrader } = useAuth();
  const navigate = useNavigate();

  const need_login_as_trader = needLoggedAsTrader && !isLoggedInAsTrader;

  useEffect(() => {
    if (isLoggedIn) {
      if (need_login_as_trader) {
          navigate('login', { replace: true });
      }
      else {
        if (isLoggedInAsTrader) {
          navigate(AppRoutes.TRADE_LAB, { replace: true });
        }
        else {
          navigate(config.defaultPath, { replace: true });
        }
      }
    }
  }, [isLoggedIn, navigate, need_login_as_trader, isLoggedInAsTrader]);

  return children;
};

export default GuestGuard;
