import { AssetBalance, Position } from './trade-types';
import React from 'react';

import { UpOutlined, CaretRightOutlined } from '@ant-design/icons';
import { balanceKey, positionKey, sortedBy, Td, Ti } from './trade-lab-common';
import { Table } from '@mui/material';
import { $$ } from '../../utils/utils';

interface TradeBalanceProps {
    balances: AssetBalance[];
    balanceHistory: any;
    onAction: (a: any) => void;
    sortMode: {};
    expanded: number;
}

const BalanceFieldNames = ['asset', 'account', 'available', 'marginBalance',
    'crossUnrealizedPnL', 'maintenanceMargin', 'balanceInitialMargin'];

const XBalanceDetail = ({balance, expand }) => {
  return <table border={0} style={{width: '60%'}}><tbody>
    {BalanceFieldNames.map((name, index) => <tr key={'balance-detail' + name}><Td>
      {index === 0 ? <UpOutlined onClick={expand}/>: <span></span>}
    </Td><Td>{name}</Td><Td>{balance[name] || ''}</Td></tr>)}
  </tbody></table>;
}


const detailValue = (balance: AssetBalance, fieldName: string) => {
  const diff = $$.getDateDiffNow(balance.ts / 1000);
  const howOld = $$.secondsToDHM(diff);
  const value = fieldName === 'ts' ? `${howOld} ago` : balance[fieldName] || '';
  return <span>{value}</span>;
}

const detailName = (fieldName: string) =>  fieldName === 'ts' ? 'was updated' : fieldName;

const BalanceDetail = ({balance, expand }) => {
  return <table border={0} style={{width: '100%'}}><tbody>
    {BalanceFieldNames.map((name, index) => <tr key={'balance-detail' + name}>
    <Td>{detailName(name)}</Td><Td>{detailValue(balance, name)}</Td></tr>)}
  </tbody></table>;
}


const TradeBalanceTable = (props: TradeBalanceProps) => {
  const { onAction, balances, sortMode, expanded, balanceHistory } = props;

  const balancesView: AssetBalance[] = [];
  balances.forEach((item, index) => {
     const _deltas = balanceHistory[balanceKey(item)] || [];
      if (index === expanded) {
        balancesView.push({...item, _viewMode: 'marked', _deltas});
        balancesView.push({...item, _viewMode: 'expanded', _deltas});
      }
      else {
        balancesView.push({...item, _viewMode: 'normal', _deltas});
      }
  });

  const applySortBy = (name: string) => {
    onAction({ mode: 'balances', name: name, value: !sortMode['value'] });
  }

  const expand = (index: number) => {
    onAction({ mode: 'balances', name: '_expand_', value: index });
  }

  const fields = [
    {name:'asset'},
    {name:'account'},
    {name:'available'},
    // {name:'0', title: 'diff', render: (balance: AssetBalance) => balance._deltas!["0"]},
  ];
  
  const getHeader = (field: any) => {
    const name = field.name;
    const title = field.title ? field.title === '_hidden_' ? '': field.title : field.name;
    return <Td key={'bh-' + name}> {title}
      <span className={'preventSelect'} onClick={() => applySortBy(name)}>{sortedBy(name, sortMode)}</span>
    </Td>};

  const getValue = (balance: AssetBalance, field: any) => {
    return <Td key={`bal-${balance.account}-${balance.asset}-${field.name}`}>
      {field.render ? field.render(balance) : balance[field.name]}
    </Td>;
  }
  

  return <Table key={'balance-main'} border={0} style={{ width: '100%' }}>
    <thead>
        <tr style={{backgroundColor: '#002553'}}><td  colSpan={fields.length + 1}>&nbsp;&nbsp;&nbsp;BALANCES</td></tr>
    <tr key={'balance-main-header'}>
      <Ti/>
      {fields.map(field => getHeader(field))}
    </tr>
    </thead><tbody>

  { balancesView.map((balance, row_index) =>
        <tr style={ row_index === expanded ? {backgroundColor: '#374860'}: {}}
            key={`pos-row-${balance.account}-${balance.asset}-${balance._viewMode}}`}>

          {balance._viewMode === 'normal' && <>
              <Ti><CaretRightOutlined onClick={() => expand(row_index)}/></Ti>
              {fields.map(field => getValue(balance, field))}
          </>}
          {balance._viewMode === 'marked' && <>
              <Ti><CaretRightOutlined onClick={() => expand(row_index)}/></Ti>
              {fields.map(field => getValue(balance, field))}
          </>}
          {balance._viewMode === 'expanded' && <>
              <Td colSpan={3}><BalanceDetail balance={balance} expand={() => expand(row_index)}/></Td>
          </>}
        </tr>
      )}

    </tbody>
  </Table>;
}

export default TradeBalanceTable;
