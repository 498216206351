import React, { createContext, useEffect, useReducer } from 'react';
import Cookies from 'universal-cookie';

import { LOGIN, LOGIN_AS_TRADER, LOGOUT } from 'store/reducers/actions';
import authReducer, { restoreUser } from 'store/reducers/auth';

import Loader from 'components/Loader';
import { AuthProps, DjangoContextType, applyAsTraderUser, UserProfile } from 'types/auth';
import { doAjaxPostAsForm } from 'api';
import AppRoutes from '../routes/AppRoutes';
import { BASE_DJANGO_URL, isTraderApp } from '../config';
import {useNavigate} from "react-router-dom";
import { wsConnectorTradeGateway, wsTradeConnectors } from '../components/system/websocket-component';
import { getCachedValue, removeCachedValue } from '../utils/local_storage';
import { $$ } from '../utils/utils';
import { WsConnector } from '../components/ws-connector';

const initialState: AuthProps = {
  metadata: {},
  needLoggedAsTrader: false,
  isLoggedInAsTrader: false,
  isLoggedIn: false,
  isInitialized: false,
  user: undefined,
  error: ''
};

const BASE_LOGIN_URL = `${BASE_DJANGO_URL}/login/api/`;

const DjangoContext = createContext<DjangoContextType | null>(null);

export const DjangoAuthProvider = ({ children }: { children: React.ReactElement }) => {
  const [state, dispatch] = useReducer(authReducer, initialState);
  const cookies = new Cookies();
  const navigate = useNavigate();
  const [userExists, setUserExists] = React.useState(true);
  const [userTrader, setUserTrader] = React.useState(false);

  setInterval(() => {
    setUserExists(!!restoreUser());
  }, 2000);

  useEffect(() => {
    const init = async () => {
      try {
        const user = restoreUser()
        if (userTrader) {
          dispatch({ type: LOGIN_AS_TRADER, payload: { isLoggedIn: !!user, user, 'needLoggedAsTrader': true } });
          navigate('login')
        }
        if (user) {
          const user = restoreUser();
          let isLoggedInAsTrader = false;
          if (!!user) {
              isLoggedInAsTrader = !!getCachedValue('_user');
          }
          dispatch({ type: LOGIN, payload: { isLoggedIn: !!user, user, isLoggedInAsTrader } });
        } else {
          dispatch({ type: LOGOUT});
          navigate('login')
        }
      } catch (err) {
        console.error(err);
        dispatch({ type: LOGOUT});
        navigate('login')
      }
    };

    init();
  }, [userExists, userTrader]);

  const login = async (email: string, password: string) => {
    const response = await doAjaxPostAsForm(BASE_LOGIN_URL, {kind: 'login', username: email, password})
    const { kind, settings, user } = response.data;
    console.log('User logged SUCCESS', user);
    dispatch({
      type: LOGIN,
      payload: { isLoggedIn: true, user }
    });
  };

  const checkLoginTtl = () => {
    const user = getCachedValue('_user');
    console.log('$$$ ########### Check login. User::', user);
    if (!user) {
        dispatch({
        type: LOGIN,
        payload: { user, isLoggedIn: false, error: 'Session expired' }
      });
    }
  }

  const need_login_as_trader = () => {
    setUserTrader(true);
  };

  const login_trader = (data: Object, password: string, metaData: any) => {
    const metadata = metaData && metaData['data'] ? metaData['data'] : {};
    const user = applyAsTraderUser(data, password, metadata);
    const isLoggedIn = !!user;
    const error = data['errMessage'] || '';

    user!.trader_info = metadata['trader_info'];
    user!.accounts_info = metadata['accounts_info'];

    dispatch({
      type: LOGIN,
      payload: { user, isLoggedIn, error, role: 'trader', 'isLoggedInAsTrader': true, metadata}
    });
  };

  const register = async (email: string, password: string, firstName: string, lastName: string, registerKey: string, company: string='') => {
    const response = await doAjaxPostAsForm(BASE_LOGIN_URL,
      {kind: 'register', username: email, password, firstName, lastName, registerKey, company});
    return response;
  };

  const logout = async () => {
    removeCachedValue('_user');
    const response = await doAjaxPostAsForm(BASE_LOGIN_URL, {kind: 'logout'});
    console.log("LOG OUT ### response::", response);
    document.location.href = AppRoutes.NB_AFTER_LOGOUT;
    dispatch({ type: LOGOUT });
  };

  const logout_trader = (user: UserProfile) => {
      wsConnectorTradeGateway.send({  'msg' : "Logoff", 'traderId': user.name, 'account': user.account });
      /* It is not required logoff for this kind of WS  */
      // for (const ws of Object.values(wsTradeConnectors)) {
      //   (ws as WsConnector).send({  'msg' : "Logoff", 'traderId': user.name, 'account': user.account });
      // }
      logout();
  }

  const resetPassword = async (email: string) => {};

  const updateProfile = () => {};

  if (state.isInitialized !== undefined && !state.isInitialized) {
    return <Loader />;
  }

  return <DjangoContext.Provider value={{
    ...state,
    login,
    logout,
    need_login_as_trader,
    login_trader,
    logout_trader,
    register,
    resetPassword,
    updateProfile
  }}>{children}</DjangoContext.Provider>;
};

export default DjangoContext;
