
export const NB_ROUTE_PREFIX = '';

const MARKET  =  NB_ROUTE_PREFIX + 'dashboard/marketSummary';
const ADMIN   =  NB_ROUTE_PREFIX + 'dashboard/admin/main';
const TRADELAB=  NB_ROUTE_PREFIX + 'tradeLab';


export default class AppRoutes {
  static NB_AFTER_LOGOUT = NB_ROUTE_PREFIX + '/login';
  static ADMIN_PAGE = ADMIN;
  static DATA_QUALITY_PAGE = NB_ROUTE_PREFIX + 'datQuality/main';
  static LIVE_DATA_PAGE = NB_ROUTE_PREFIX + 'liveData/main';
  static NB_HOME = MARKET;
  static NB_ANALYTICS = NB_ROUTE_PREFIX + 'analytics';
  static DASHBOARD_MONITOR = NB_ROUTE_PREFIX + 'dashboard/monitor';
  static DASHBOARD_MARKET_SUMMARY = MARKET;
  static DASHBOARD_SCREENSHOT = NB_ROUTE_PREFIX + 'dashboard/marketSummary/screenshot';
  static DASHBOARD_CRYPTO_OVERVIEW = NB_ROUTE_PREFIX + 'dashboard/cryptoOverview';
  static PORTFOLIOS_MANAGE_PORTFOLIOS = NB_ROUTE_PREFIX + 'portfolios/managePortfolios';
  static WEALTH_LAB = NB_ROUTE_PREFIX + 'wealthLab';
  static TRADE_LAB = TRADELAB;
  static PORTFOLIOS = NB_ROUTE_PREFIX + 'portfolios';
  static COMMON_lAB = 'common/common';
  static RESEARCH_PRODUCTS = NB_ROUTE_PREFIX + 'researchProducts';
  static PORTFOLIO_VIEW_OVERVIEW = NB_ROUTE_PREFIX + 'overview';
}
