import { DefaultConfigProps } from 'types/config';
import AppRoutes from './routes/AppRoutes';

export const BASE_ASYNC_URL = process.env.REACT_APP_BASE_URL;

export const BASE_DJANGO_URL = process.env.REACT_APP_DJANGO_APP_BASE_URL;
export const REACT_APP_GITTAG = process.env.REACT_APP_GITTAG;
export const REACT_APP_BUILDTIME = process.env.REACT_APP_BUILDTIME;
export const REACT_APP_ENVINROMENT = process.env.REACT_APP_ENVINROMENT;
export const BASE_RESEARCH_URL = process.env.REACT_APP_RESEARCH_APP_BASE_URL;

export const BASE_DATAPROCESSOR_URL = process.env.REACT_APP_DATAPROCESSOR_APP_BASE_URL;
export const WS_DATAPROCESSOR_URL = process.env.REACT_APP_DATAPROCESSOR_APP_WS_URL;

export const WS_MARKET_URL = process.env.REACT_APP_WS_URL as string;
export const WS_RESEARCH_URL = process.env.REACT_APP_RESEARCH_WS_URL as string;
export const RESOLUTION_SCREEN = window.screen.width > window.screen.height ? 'horizontal' : 'vertical';
export const REACT_APP_MAX_INSTRUMENTS = process.env.REACT_APP_MAX_INSTR ? +process.env.REACT_APP_MAX_INSTR : 50;

export const WS_GATEWAY_URL = process.env.REACT_APP_GATEWAY_WS_URL as string;
export const WS_GATEWAY_LOGIN_URL = process.env.REACT_APP_GATEWAY_LOGIN_WS_URL as string;


export const APPLICATION_MODE = 'PLATFORM TRADER ADMIN' as string; // as APPLICATION_MODE_TYPE;
export const isTraderApp = () => APPLICATION_MODE.includes('TRADER');
export const isPlatformApp = () => APPLICATION_MODE.includes('PLATFORM');
export const isAdminApp = () => APPLICATION_MODE.includes('ADMIN');

export const drawerWidth = 0;

export const mainBackgroundColor = '#000A2B';
// export const controlBackgroundColor = '#002553';
export const controlBackgroundColor = '#000A2B';
export const headerBackgroundColor = '#002553';

export const mainWarmShadeBgColor = '#FFCB82';
export const secondaryWarmShadeBgColor = '#FFBB96';
export const lightWarmShadeBgColor = '#FFCB82';
export const mainColdShadeBgColor = '#1A3C65';
export const secondaryColdShadeBgColor = '#67A4B4';
export const lightColdShadeBgColor = '#B5D7DC';


export const mainTextColor = '#FFF';
export const primaryTextColor = '#CDBEB4';
export const secondTextColor = '#AEA49B';
export const buttonBackgroundColor = '#FF8A53';
export const errorTextColor = '#C24046';
export const twitterColor = '#1DA1F2';
export const facebookColor = '#3b5998';
export const linkedInColor = '#0e76a8';


const config: DefaultConfigProps = {
  // defaultPath: isTraderApp() ? AppRoutes.TRADE_LAB : AppRoutes.DASHBOARD_MARKET_SUMMARY,
  defaultPath: AppRoutes.DASHBOARD_MARKET_SUMMARY,
  fontFamily: `'Public Sans', sans-serif`,
  i18n: 'en',
  miniDrawer: true,
  container: true,
  mode: 'light',
  presetColor: 'default',
  themeDirection: 'ltr'
};

export const styledControlColorFix = {
  backgroundColor: controlBackgroundColor
}


// export const extraLogging = false;
export const extraLogging = true;
export const applyDummyData = () => false;
export const applyDummyDataForMainpage = () => false;

export const logGrouped = false;
export default config;


export const traderUserTtlSeconds = 60 * 60 * 24;
export const traderPositionTtlSeconds = 60 * 60 * 24;
