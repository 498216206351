// action - state management
import { REGISTER, LOGIN, LOGOUT, LOGIN_AS_TRADER } from './actions';

// types
import { AuthProps, AuthActionProps } from 'types/auth';
import { User } from '../../models/user';
import {goTo} from "../../utils/react-utils";
import AppRoutes from "../../routes/AppRoutes";

// initial state
export const initialState: AuthProps = {
  metadata: {},
  needLoggedAsTrader: false,
  isLoggedInAsTrader: false,
  isLoggedIn: false,
  isInitialized: false,
  user: null,
  role: '',
  error: '',
  mess: ''
};


const LOCAL_STORAGE_USER_KEY = 'user';
export const restoreUser = () => {
  if (window.localStorage.getItem(LOCAL_STORAGE_USER_KEY)) {
    const userStr = window.localStorage.getItem(LOCAL_STORAGE_USER_KEY);
    // @ts-ignore
    return JSON.parse(userStr);
  }
  return undefined;
}

export const resetUser = () => {
  window.localStorage.removeItem(LOCAL_STORAGE_USER_KEY);
  window.localStorage.removeItem('_user');
}

export const storeUser = (user: User, metadata={}) => {
  window.localStorage.setItem(LOCAL_STORAGE_USER_KEY, JSON.stringify({...user, ...metadata}));
}

// ==============================|| AUTH REDUCER ||============================== //

const auth = (state = initialState, action: AuthActionProps) => {
  switch (action.type) {
    case REGISTER: {
      const { user } = action.payload!;
      return { ...state, user };
    }
    case LOGIN_AS_TRADER: {
       return {
          ...state,
          needLoggedAsTrader: true,
          isLoggedInAsTrader: false,
          isLoggedIn: true,
          isInitialized: true,
          user: undefined,
          role: 'trader',
        };
    }
    case LOGIN: {
      const { user, isLoggedIn, error, role, isLoggedInAsTrader, metadata } = action.payload!;
      if (user) {
        storeUser(user as User, metadata);
      }
      return {
        ...state,
        isLoggedIn: isLoggedIn,
        isInitialized: true,
        isLoggedInAsTrader,
        user,
        error,
        metadata,
        role: role || '',
      };
    }
    case LOGOUT: {
      resetUser();
      return {
        ...state,
        isInitialized: true,
        isLoggedIn: false,
        user: null
      };
    }
    default: {
      return { ...state };
    }
  }
};

export default auth;
