import { ReactElement } from 'react';
import { setCachedValue } from '../utils/local_storage';
import { traderUserTtlSeconds } from '../config';

// ==============================|| AUTH TYPES  ||============================== //

export type GuardProps = {
  children: ReactElement | null;
};

export type UserProfile = {
  id?: string;
  email?: string;
  password?: string;
  avatar?: string;
  image?: string;
  name?: string;
  firstName?: string;
  lastName?: string;
  role?: string;
  tier?: string;
  extra: string;
  token?: string | null;
  trader_id?: string | null;
  account?: string | null;
  accounts_info?: [];
  trader_info?: Object;
};

export const applyAsTraderUser = (data: Object, password: string, metadata:any): UserProfile | undefined => {
  if(!data || data['result'] !== "success") {
    return undefined;
  }
  const user = {
    name: data['traderId'],
    role: 'trader',
    trader_id: data['trader_id']
  } as UserProfile;

  setCachedValue('_user', {...user, password}, traderUserTtlSeconds);
  return user;
}

export const isSuperuser = (user: UserProfile) => user.extra === '1:1:USER:none:None';

export interface AuthProps {
  metadata?: object;
  needLoggedAsTrader?: boolean;
  isLoggedInAsTrader?: boolean;
  isLoggedIn: boolean;
  isInitialized?: boolean;
  user?: UserProfile | null;
  error?: string,
  mess?: string,
  role?: string;
}

export interface AuthActionProps {
  type: string;
  payload?: AuthProps;
}

export type DjangoContextType = {
  needLoggedAsTrader?: boolean;
  isLoggedInAsTrader?: boolean;
  isLoggedIn: boolean;
  isInitialized?: boolean;
  user?: UserProfile | null | undefined;
  logout: () => void;
  logout_trader: (user: UserProfile) => void;
  need_login_as_trader: () => void;
  login: (email: string, password: string, extra_params?:{country:string, investor:string}) => Promise<void>;
  login_trader: (data: Object, password: string, metaData: any) => void;
  register: (email: string, password: string, firstName: string, lastName: string, registerKey: string, company: any) => Promise<any>;
  resetPassword: (email: string) => Promise<void>;
  updateProfile: VoidFunction;
  error?: string | null;
};
