import { AccountInfo, AssetBalance, Trade } from './trade-types';
import React, { useEffect, useRef, useState } from 'react';

import {
  UpOutlined,
  CaretRightOutlined,
  CheckCircleOutlined,
  CaretDownOutlined,
  SyncOutlined
} from '@ant-design/icons';
import { REQUEST_INTERVAL, sortedBy, Ta, Td, Ti } from './trade-lab-common';
import { Table } from '@mui/material';
import { CheckCircleFilled } from '@ant-design/icons/lib/icons';
import { $$ } from 'utils/utils';

interface TradeAccountProps {
    accounts: AccountInfo[],
    showList: boolean,
    toggleSelect: (AccountInfo: any) => void,
    toggleShowList: () => void,
    errorMessage: string;
    sincTime: number;
    trades: Trade[];
}

const TradeAccountTable = (props: TradeAccountProps) => {
  const { accounts, showList, toggleSelect, toggleShowList, errorMessage, sincTime, trades } = props;
  const visibleAccounts = accounts.map((account: AccountInfo) => account._viewMode === 'applied' && !account._error ? account.account_name : null).filter(it => !!it);
  const visibleAccountsStr = visibleAccounts.join(', ')

  const lastTicks = accounts.map(a => a._lastTick || 0);
  const lastTick = lastTicks.reduce((t1, t2) => Math.max(t1, t2), 0);
  const sincTimeStr = $$.getDateTimeAsStringFromMs(sincTime);
  const lastUpdatedDHM = $$.secondsToDHM($$.getDateDiffNow(lastTick), true);

  const [tradesCount, setTradesCount] = useState(trades.length);
  const [tradesCountColor, setTradesCountColor] = useState('white');

  useEffect(() => {
    if (trades.length !== tradesCount) {
      setTradesCount(trades.length);
      setTradesCountColor('yellow');
    }
    else {
      setTradesCountColor('white');
    }
  }, [trades.length, sincTime]);


  const getUpdateColor = (tick: number | null = null, account: AccountInfo | null = null) => {
    const normal = 'white';
    const warn = 'yellow';
    const error = 'red';

    if (!!account && account._viewMode !== 'applied') return normal;
    if (!tick) return normal

    const diff = sincTime - tick!;
    return diff < (REQUEST_INTERVAL - 2) * 1000   ? normal : diff < REQUEST_INTERVAL * 1000 * 1.2  ? warn : error;
  }

  const responseCounter = (account: AccountInfo) => {
    return account._tradesTracker ? account._tradesTracker!.responseCounter : 0;
  }

  const getLastTime = (account: AccountInfo) => {
    if (account._viewMode !== 'applied') return '**';
    const lastTick = account._lastTick || 0;
    return lastTick === 0 ? '**' : $$.secondsToDHM($$.getDateDiffNow(lastTick));
  }

  const icon_form = (account: AccountInfo) =>
    account._loading ? <SyncOutlined spin /> :
      account._viewMode === 'applied' ? <CheckCircleFilled /> : <CheckCircleOutlined />;

  const icon_color = (account: AccountInfo) =>
    account._error ? 'red' : account._viewMode === 'applied' ? 'white': 'grey';

  const text_color = (account: AccountInfo) =>
    account._error ? 'grey' : account._viewMode === 'applied' ? 'white': 'grey';

  const getCountTrades = (account: AccountInfo) =>
      trades.filter(trade => trade.account_name === account.account_name).length;

  return <Table key={'account-main'} border={0} style={{ width: '100%' }}>
    <thead>
    <tr style={{backgroundColor: '#002553'}}>
      <td colSpan={5}>
        <span onClick={toggleShowList}>{ showList ? <CaretDownOutlined/> : <CaretRightOutlined/>}</span>
        &nbsp;&nbsp;ACCOUNTS
        &nbsp;&nbsp;&nbsp;&nbsp;[{visibleAccountsStr}]
        &nbsp;&nbsp;&nbsp;&nbsp;<span style={{color: 'red'}}>{errorMessage}</span>
      </td>
      <td colSpan={3} style={{textAlign: 'right'}}>
        <span title={'current time'} style={{color: getUpdateColor(lastTick)}}>{sincTimeStr}</span>&nbsp;
        <span title={`last update was ${lastUpdatedDHM} ago`}>[{lastUpdatedDHM}]</span>&nbsp;</td>
    </tr>
    {showList &&
    <tr key={'account-main-header'}>
      <Ta>&nbsp;</Ta>
      <Td>account_id</Td>
      <Td>client</Td>
      <Td>account</Td>
      <Td>exchange</Td>
      <Td>strategy</Td>
      <Td>updated</Td>
      <Td>trades</Td>
    </tr> }
    </thead><tbody>
      {showList && <>
      {accounts.map(account => <tr style={{color: text_color(account)}} key={`${account.account_name}`}>
        <Ti onClick={() => toggleSelect(account)}>
          <span title={account._error} style={{color: icon_color(account)}}>{icon_form(account)}</span>
        </Ti>
        <Td>{account.account_id}</Td>
        <Td>{account.client_name}</Td>
        <Td>{account.account_name}</Td>
        <Td>{account.exchange_name}</Td>
        <Td>{account.strategy_name}</Td>
        <Td><span style={{color: getUpdateColor(account._lastTick, account)}}>{getLastTime(account)}</span>
          {/*&nbsp;&nbsp;<span>{responseCounter(account) > 0 ? `[${responseCounter(account)}]` : null}</span>*/}
          &nbsp;
        </Td>
        <Td><span style={{color: tradesCountColor}}>{getCountTrades(account)}</span></Td>
      </tr>)}</>}

    </tbody>
  </Table>;
}

export default TradeAccountTable;
