// copy Sergii Tretiak = tretyak@gmail.com  MIT

import { round } from 'lodash';

export const $$ = {
    lastUniqueId: "",
    uniqueId(baseId = ""): string {
        const maxRandomSize = 6 + 6,
            randomStr = "" + Math.random(),
            randomId = "" + baseId + randomStr.substring(2, maxRandomSize);
        this.lastUniqueId = baseId;
        return randomId;
    },
    zeroPad(num, places) {
        return String(num).padStart(places, '0')
    },

    randomInt(min: number, max: number) {
        return Math.floor(Math.random() * (max - min + 1)) + min;
    },

    // @todo - not tested properly - remove
    // startIntervalRated(routine: () => void, interval: number ): any  {
    //     const now = new Date();
    //     console.log("now", now);
    //     const seconds = Math.floor(now.getTime() / 1000);
    //     console.log("seconds", seconds);
    //     const needStartTimeout = seconds % interval;
    //     console.log("needStartTimeout", needStartTimeout);
    //
    //     let intervalTimeout;
    //
    //     const timeout = setTimeout(() => {
    //         intervalTimeout = setInterval(routine, interval);
    //     }, needStartTimeout);
    //     return [timeout, intervalTimeout];
    // },
    durationForHuman(duration_seconds: number, skip_days_if_possible= true, skip_hours_if_possible= true) {
        const days = $$.zeroPad(Math.floor(duration_seconds / 3600 / 24), 2);
        //const hours = $$.zeroPad(Math.floor(duration_seconds / 3600), 2);
        const hours   = $$.zeroPad(Math.floor(Math.floor(Math.floor(duration_seconds % (3600 * 24)) / 3600)), 2);
        const minutes = $$.zeroPad(Math.floor(Math.floor(Math.floor(duration_seconds % 3600) / 60)), 2);
        const seconds = $$.zeroPad(Math.floor(duration_seconds % 60), 2);

        if (days === '00' && hours === '00' && skip_hours_if_possible) {
            return `${minutes}:${seconds}`;
        }
        if (days === '00' && skip_days_if_possible) {
            return `${hours}:${minutes}:${seconds}`;
        }
        return `${days} ${hours}:${minutes}:${seconds}`;
    },
    getDateStringCurrentTZ(unix_timestamp: number) {
      const date = new Date(unix_timestamp * 1000);
      return ''   + $$.zeroPad(date.getHours(),2)
            + ':' + $$.zeroPad(date.getMinutes(),2)
            + ':' + $$.zeroPad(date.getSeconds(),2);
    },
    getDateString(unix_timestamp: number) {
      const date = new Date(unix_timestamp * 1000);
      const parts = date.toUTCString().split(' ')
      return `${parts[4]} UTC`
    },
    getDateTimeString(unix_timestamp: number) {
      const date = new Date(unix_timestamp * 1000);
      return date.toUTCString();
    },
    getDateTimestampMs(date: Date | undefined = undefined) {
        const d = date || new Date();
        return d.getTime();
    },
    getDateTimeAsStringFromMs(timestamp: number) {
        return $$.getDateTimeString(timestamp / 1000);
    },
    getDateAsString(date: Date | undefined = undefined) {
        const d = date || new Date();
        return $$.getDateString(d.getTime() / 1000);
    },
    getDateFromString(dateString: string) {
        return Date.parse(dateString);
    },
    getDateDiffNow(unix_timestamp_org: number) {
      const unix_timestamp = unix_timestamp_org > 17095557770 ? unix_timestamp_org / 1000 : unix_timestamp_org;
      const timestamp_now = new Date().getTime() / 1000;
      // console.log('')
      // console.log('################## org:: [' + unix_timestamp_org + ']')
      // console.log('################## new:: [' + unix_timestamp + ']')
      // console.log('################## NOW:: [' + timestamp_now + '] ... diff::' + round((timestamp_now - unix_timestamp), 2))
      return round((timestamp_now - unix_timestamp), 2);
    },
    secondsToDHM(seconds: number, skip_hours = false) {
        const secsPerDay = 86400;
        const secsPerHour = 3600;
        const secsPerMinute = 60;

        const days    = Math.floor(seconds / secsPerDay);       seconds = seconds % secsPerDay;
        const hours   = Math.floor(seconds / secsPerHour);      seconds = seconds % secsPerHour;
        const minutes = Math.floor(seconds / secsPerMinute);    seconds = seconds % secsPerMinute;

        const sDays    = $$.zeroPad(days, 1);
        const sHours   = $$.zeroPad(hours, 1);
        const sMinutes = $$.zeroPad(minutes, 2);
        const sSeconds = $$.zeroPad(Math.floor(seconds), 2);

        return days > 0
          ? `${sDays}d ${sHours}h ${sMinutes}:${sSeconds}`
          : sHours === '0' && skip_hours
            ? `${sMinutes}:${sSeconds}`
            : `${sHours}h ${sMinutes}:${sSeconds}`;
    },

    getToday() {
        return new Date((new Date()).valueOf());
    },
    getYesterday() : Date {
        return new Date((new Date()).valueOf() - 1000*60*60*24);
    },
    getOneYearAgo() :Date {
        return new Date((new Date()).valueOf() - 1000*60*60*24*366)
    },
    isDefined(value: any): boolean {
        return typeof(value) !== 'undefined' && value !== null
    },
    isArray: function(value: any): boolean {
        return Array.isArray(value)
    },
    isObject(value): boolean {
        return typeof value === 'object'
          && !Array.isArray(value)
          && value !== null
    },
    isNonEmptyArray: function(value: any): boolean {
        return Array.isArray(value) && value.length > 0;
    },
    isString : function (value: any): boolean {
        return (typeof value === 'string' && value.length > 0)
    },
    isFunction : function (value: any): boolean {
        return $$.isDefined(value) && (typeof value === 'function')
    },
    isArrayBuffer(value: any): boolean {
        return value && value instanceof ArrayBuffer && value.byteLength !== undefined;
    },
    isBlob(value: any): boolean {
        if ($$.isDefined(value) && $$.isString(value)) {
            if ( value[0] === "b" && value[1] === "'") {
                return true
            }
        }
        return false
    },
    isIncludes: function(arr: any, value: any): boolean {
      return $$.isArray(arr) ? arr.includes(value) : false
    },
    replaceNonAlphaChars(value: string, replacer= '-'): string {
        return value.replace(/\W/g, replacer)
    },
    toLower (value: any): string {
        return $$.isString(value) ? value.toLowerCase() : "";
    },
    toUpper (value: any): string {
        return $$.isString(value) ? value.toUpperCase() : "";
    },
    getDottedValue: function(key, dict): any {
        if (! dict) {
            return undefined;
        }
        const idx = key.indexOf(".");
        if (idx === -1) {
            return dict[key];
        }
        const a = key.substring(0, idx),
              b = key.substring(idx + 1);
        return this.getDottedValue(b, dict[a])
    },
    safeGet(obj: Record<string, any>, params: string[], defaultValue: any = null) {
        if (! $$.isObject(obj)) {
            return this.isDefined(defaultValue) ? defaultValue : obj
        }
        const name = params.shift() as string;
        if (params.length > 0) {
            return $$.safeGet(obj[name], params, defaultValue)
        }
        return $$.isDefined(obj[name]) ? obj[name] : defaultValue
    },
    safeSet(obj: Record<string, any>, params: string[], value: any) {
        const name = params.shift() as string,
              size = params.length;

        if (! $$.isDefined(obj[name])) {
            obj[name] = size > 0 ? {} : value
        }
        return size > 0 ? $$.safeSet(obj[name], params, value) : obj[name] = value;
    },
    checkedInsert(arr1, arr2) {
        // insert all items from arr1 to arr2 checking if them are't in arr1
        if ($$.isDefined(arr2)) {
            if ($$.isArray(arr1)) {
                arr2 = $$.isArray(arr2) ? arr2 : [arr2]
                arr2.forEach(item => {
                    if (!$$.isIncludes(arr1, item)) {
                        arr1.push(item)
                    }
                })
            }
        }
        return arr1
    },
    firstChars(val: string, count: number, safed = false): string {
        val =  safed ? val : "" + val;
        return val.slice(0, count)
    },

    lastChars(val: string, count: number, safed = false): string {
        val =  safed ? val : "" + val;
        return val.slice(-count)
    },

    skipLastChars(val: string, count: number, safed = false): string {
        val =  safed ? val : "" + val;
        return val.slice(0, -count)
    },

    raiseEvent(eventName, details:string | null =null, element=null): boolean {
        // @ts-ignore
        element = element || window.document;
        const nativeEvent = new CustomEvent(eventName, {detail: details || {}});
        // @ts-ignore
        return element.dispatchEvent(nativeEvent);
    },

    mouseEvent(eventName, element, x, y) {
      const event = new MouseEvent(eventName, {
            'view': window,
            'bubbles': true,
            'cancelable': true,
            'clientX': x || 0,
            'clientY': y || 0
        });
        element.dispatchEvent(event);
    },
    addEventListener(element, event, handler) {
        // @ts-ignore
        element = element || window.document;
        if (this.isDefined(element)) {
            if (element.attachEvent)      { return element.attachEvent('on' + event, handler) }
            if (element.addEventListener) { return element.addEventListener(event, handler, true) }
            element['on' + event] = handler;
        }
    },
    removeEventListener(element, event, handler) {
        // @ts-ignore
        element = element || window.document;
        if (this.isDefined(element)) {
            if (element.detachEvent)         { return element.detachEvent('on' + event, handler) }
            if (element.removeEventListener) { return element.removeEventListener(event, handler, true) }
            element['on' + event] = null;
        }
    },
    isLikeAsJsonStr(str: string) {
        const   f = this.firstChar(str),
                l = this.lastChar(str);
        return f === '{' && l === '}' || f === '[' && l === ']'
    },
    isLikeAsUrlStr(str) {
        if (this.isString(str)) {
            return this.firstChars(str, 4, "checked") === 'www.'
                || this.firstChars(str, 7, "checked") === 'http://'
                || this.firstChars(str, 8, "checked") === 'https://'
        }
        return false
    }
};
