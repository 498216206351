export interface TradeCommonMess {
  seqn: string;
  ts: number
}

export interface Trade extends TradeCommonMess {
  clientOrderId: number;
  commission: string;
  commissionAsset: string;
  exchangeOrderId: string;
  execQty: string;
  maker: boolean;
  side: "BUY" | "SELL";
  status: "FILLED" | "PARTIALLY_FILLED";
  symbol: string;
  tradeId: string;
  tradePrice: string;
  account_name?: string;  // injected
}

export interface AssetBalance extends TradeCommonMess {
  account: string;
  asset: string;
  available: string;
  crossUnrealizedPnL: string;
  initialMargin: string;
  maintenanceMargin: string;
  marginBalance: string;
  positionInitialMargin: string;
  _viewMode?: string;
  _deltas?: {};
}

export interface Position extends TradeCommonMess {
  "account": string;
  "position":string;
  "instrumentCode":string;
  "symbol":string;
  "initialMargin":string;
  "maintenanceMargin":string;
  "positionInitialMargin":string;
  "unrealizedPnL":string;
  "leverage":string;
  "entryPx":string;
  _viewMode?: 'marked' | 'expanded' | 'normal' | undefined;
  _deltas?: {};
}

export interface TradesTracker {
  responseCounter: number;
  lastPackSize: number;
}
export const initialTradesTracker = () => ({
  responseCounter: 0,
  lastPackSize: 0,
  });

export interface AccountInfo {
      account_id: number,
      subaccount_id: number,
      account_name: string,
      client_id: number,
      client_name: string,
      exchange_id: number,
      exchange_name: string,
      strategy_id: number,
      strategy_name: string,

      _viewMode?: 'applied' | 'not_applied';
      _loading?: boolean;
      _error?: string;
      _lastTick?: number;
      _tradesTracker?: TradesTracker;
}

export interface TraderInfo {
      trader_id: number,
      trader_name: string,
      accound_ids: number[],
      trader_created_at: string,
      accounts_info: AccountInfo[]
}

// @todo remove below
const MetaDataResponseExample = {
  "msg": "MetaData",
  "ts": 1739022288828873,
  "seqn": 5,
    "data": 
      {"trader_info":
        {"trader_id": 100204, 
          "trader_name": "sergii001",
          "accound_ids": [1011304, 1010204],
          "trader_created_at": "2025-01-29T18:55:30.702930"},
          "accounts_info": [
            { "account_id": 1011304,
              "subaccount_id": 1,
              "account_name": "TST.01.BNF.LSH",
              "client_id": 1,
              "client_name": "TST",
              "exchange_id": 13,
              "exchange_name": "BNF",
              "strategy_id": 4,
              "strategy_name": "LSH"
            },
            {"account_id": 1010204,
              "subaccount_id": 1,
              "account_name": "TST.01.BNS.LSH",
              "client_id": 1,
              "client_name": "TST",
              "exchange_id": 2,
              "exchange_name": "BNS",
              "strategy_id": 4,
              "strategy_name": "LSH"
            }]},
  "result": "success"}

const AssetBalanceExample = {
  "msg":"AssetBalance",
  "ts":1738773873263994,
  "account":"TST.01.BNF.LSH",
  "asset":"USDT",
  "available":"13394.04",
  "initialMargin":"380.34",
  "maintenanceMargin":"50.17",
  "positionInitialMargin":"380.34",
  "marginBalance":"10995.89",
  "unrealizedPnL":"-2398.15",
  "crossUnrealizedPnL":"-2398.15",
  "seqn":5}

const PositionExample = {
  "msg":"Position",
  "ts":1738773149151025,
  "account":"TST.01.BNF.LSH",
  "position":"-1.0",
  "instrumentCode":"BNF.UNIUSDT.R",
  "symbol":"UNIUSDT",
  "initialMargin":"0.46411",
  "maintenanceMargin":"0.05569",
  "positionInitialMargin":"0.46411",
  "unrealizedPnL":"4.68889",
  "leverage":"20",
  "entryPx":"13.971",
  "seqn":12};


// const asFloat = (value: string) => parseFloat(value);
//
// const asTrade = (data: any) => {
//     const fields = [
//     {name:'ts'},
//     {name:'symbol'},
//     {name:'tradePrice',     transform: asFloat},
//     {name:'side'},
//     {name:'status'},
//     {name:'maker'},
//     {name:'execQty',        transform: asFloat},
//     {name:'commission',     transform: asFloat},
//     {name:'commissionAsset'},
//     {name:'clientOrderId',},
//     {name:'exchangeOrderId'},
//     {name:'tradeId'}];
// }
